/* eslint no-console:0 */

import { Application } from "stimulus"
require("@rails/ujs").start()
require("turbolinks").start()

import '@fortawesome/fontawesome-free/js/all'

import { definitionsFromContext } from "stimulus/webpack-helpers"
require("stylesheets/application.scss")

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

// Images (jpg, jpeg, png, svg) are imported  here.
// This will copy all static images under `frontend/images` to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'hero.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import Clipboard from 'clipboard';

document.addEventListener('turbolinks:load', function() {
  new Clipboard('.clipboard-btn');
});
