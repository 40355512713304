import { Controller } from "stimulus"

export default class extends Controller {
  toggleRoleLabel(event) {
    let label = event.currentTarget.nextElementSibling;
    if (event.currentTarget.selectedIndex >= 1) {
      label.classList.add('visible');
    } else {
      label.classList.remove('visible');
    }
  }
}
